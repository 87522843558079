exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academias-js": () => import("./../../../src/pages/academias.js" /* webpackChunkName: "component---src-pages-academias-js" */),
  "component---src-pages-atletas-js": () => import("./../../../src/pages/atletas.js" /* webpackChunkName: "component---src-pages-atletas-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-hapkido-js": () => import("./../../../src/pages/hapkido.js" /* webpackChunkName: "component---src-pages-hapkido-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-protected-js": () => import("./../../../src/pages/protected.js" /* webpackChunkName: "component---src-pages-protected-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-academy-js": () => import("./../../../src/templates/academy.js" /* webpackChunkName: "component---src-templates-academy-js" */),
  "component---src-templates-athlete-js": () => import("./../../../src/templates/athlete.js" /* webpackChunkName: "component---src-templates-athlete-js" */)
}

